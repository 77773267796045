<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <div class="grid">
        <div class="col-12">
          <Toast />
          <form @submit.prevent="validate()">
            <div class="p-fluid formgrid grid">
              <div class="field col-6">
                <label for="nome" class="required">Nome</label>
                <InputText
                  id="nome"
                  v-model="orgao.nome"
                  :class="{ 'p-invalid': submitted && v$.orgao.nome.$invalid }"
                  type="text" />
                <div
                  v-if="submitted && v$.orgao.nome.required.$invalid"
                  class="p-error">
                  O campo Nome é obrigatório.
                </div>
              </div>
              <div class="field col-3">
                <label for="sigla" class="required">Sigla</label>
                <InputText
                  id="sigla"
                  v-model="orgao.sigla"
                  :class="{ 'p-invalid': submitted && v$.orgao.sigla.$invalid }"
                  type="text" />
                <div
                  v-if="submitted && v$.orgao.sigla.required.$invalid"
                  class="p-error">
                  O campo Sigla é obrigatório.
                </div>
              </div>
              <div class="field col-3">
                <label for="entidade" class="required">Entidade</label>
                <Dropdown
                  id="entidade"
                  v-model="orgao.entidade"
                  :options="entidadeItems"
                  :class="{
                    'p-invalid': submitted && v$.orgao.entidade.$invalid,
                  }"
                  optionLabel="nome"
                  placeholder="Selecione a entidade" />
                <div
                  v-if="submitted && v$.orgao.entidade.required.$invalid"
                  class="p-error">
                  O campo Entidade é obrigatório.
                </div>
              </div>
            </div>
            <div v-if="id">
              <div>Ativo</div>
              <div>
                <InputSwitch v-model="orgao.ativo" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Orgao from '@/domain/Orgao.js'
import OrgaoService from '@/service/OrgaoService.js'
import EntidadeService from '@/service/EntidadeService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsorgao: {
      type: Object,
      required: false,
      default: new Orgao(),
    },
  },
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      orgao: new Orgao(),
      id: this.$route.params.id,
      submitted: false,
      submitStatus: null,
      entidadeItems: [{}],
      confirmDialog: false,
      saving: false,
    }
  },

  validations() {
    return {
      orgao: {
        nome: { required },
        sigla: { required },
        entidade: { required },
      },
    }
  },

  created() {
    this.entidadeService = new EntidadeService(this.$http)
    this.carregarEntidade()
    this.service = new OrgaoService(this.$http)
    if (this.id) {
      this.service.getOrgao(this.id).then((res) => {
        this.orgao = res
      })
    }
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Orgão' : 'Nova orgão'
    },
    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.orgao = JSON.parse(JSON.stringify(this.propsorgao))
      this.id = this.orgao?.id
      if (!this.id) {
        this.orgao.ativo = false
      }
    },

    onHide() {},
    validate() {
      this.submitted = true
      this.v$.orgao.$touch()

      if (this.v$.orgao.$invalid) {
        this.submitStatus = 'error'
        return
      } else {
        this.submitStatus = 'PENDING'
        this.salvar()

        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)
      }
    },

    salvar() {
      this.saving = true
      this.orgao.alteradoPor = this.alteradoPor
      this.orgao.cadastradoPor = this.cadastradoPor
      this.service
        .saveOrgao(this.orgao)
        .then(
          () => {
            if (this.id) {
              this.exibeToast('update')
            } else {
              this.exibeToast('save')
            }
          },
          (err) => {
            this.saving = false
            if (this.id) {
              this.exibeToast('update-error', err.response.data.message)
            } else {
              this.exibeToast('save-error', err.response.data.message)
            }
          },
        )
        .finally(() => {
          this.saving = false
          this.fechar()
        })
    },

    exibeToast(tipo, msg) {
      if (tipo === 'save') {
        this.$toast.add({
          severity: 'success',
          summary: 'Órgão salvo com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'save-error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'update') {
        this.$toast.add({
          severity: 'success',
          summary: 'Órgão Atualizado com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'update-error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      }
    },

    carregarEntidade() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidadeItems = res
      })
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
