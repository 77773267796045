export default class OrgaoService {
  constructor(http) {
    this._http = http
  }

  async getOrgaos() {
    const { data } = await this._http.get('/api/orgaos/list')
    return data
  }

  async saveOrgao(orgao) {
    if (orgao.id) {
      return await this._http.put('/api/orgaos', orgao)
    } else {
      return await this._http.post('/api/orgaos/', orgao)
    }
  }

  async deleteOrgao(orgao) {
    await this._http.delete(`/api/orgaos/${orgao.id}`)
  }

  async getOrgao(id) {
    const { data } = await this._http.get(`/api/orgaos/${id}`)
    return data
  }
}
